import React from 'react';
import {Typography} from '@material-ui/core';
import {get} from 'lodash';
import {useIntl} from 'react-intl';
import {CustomShipmentIcon, HoldingTimeIcon} from '../../../constants/images';
import StatusStateIndicator from '../../Common/Indicator/StatusStateIndicator';
import SalesRow from '../Details/SalesRow';
import DeliveryDetails from "../Details/DeliveryDetails";

const PaperTicketBlock = ({ saleDetails }) => {
  const {isDelivered, sale, showDeliverTimeLeft, deliverTimeLeft, ticket} = saleDetails;
  const intl = useIntl();

  const shipmentTitle = (
    <Typography variant="body2" component="b">
      {intl.formatMessage({id: 'dashboard.sales.details.paper_delivery'})}
      {ticket &&
        ticket.hasMembership &&
        ` (${intl.formatMessage({id: 'dashboard.sales.details.membership'})})`}
    </Typography>
  );

  if (!isDelivered) {
    return (
      <div className="card details-card sales-details mx-0 mt-3">
        <div className="card-header bg-light-blue">
          <div className="row align-items-center gx-2">
            <div className="col-auto">
              <span className="icon-wrapper blue">
                <CustomShipmentIcon style={{fontSize: 15}} />
              </span>
            </div>
            <div className="col-auto">{shipmentTitle}</div>
            <div className="col-auto">
              <StatusStateIndicator status={sale.status} color="gray" />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="border-bottom pb-2">
            <div className="row gx-2 align-items-center pb-1">
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography variant="body2">
                    <b>
                      {intl.formatMessage({
                        id: 'dashboard.sales.details.delivery_details',
                      })}
                    </b>
                  </Typography>
                  {showDeliverTimeLeft && <HoldingTimeIcon />}
                </div>
              </div>
              {showDeliverTimeLeft && (
                <>
                  <div className="col-auto col-lg-3">
                    <Typography variant="subtitle2" color="textSecondary">
                      {intl.formatMessage({
                        id: 'dashboard.sales.details.delivery_time_left',
                      })}
                    </Typography>
                  </div>
                  <div className="col-auto col-lg-3">
                    <Typography variant="subtitle2">
                      {deliverTimeLeft} left
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row pt-2 mt-1">
            <div className="col-lg-6">
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'dashboard.sales.details.there_is_no_delivery',
                })}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isExternal = sale.customDeliveryModeSelected === 'external';

  return (
    <div className="card details-card sales-details mx-0 mt-3">
      <div className="card-header bg-light-blue">
        <div className="row gx-2">
          <div className="col-auto">
            <span className="icon-wrapper blue">
              <CustomShipmentIcon style={{fontSize: 15}} />
            </span>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              {shipmentTitle}
              {/* If the sale status is delivered, and the status is set by the admin, display "Delivered by the admin" instead of "Delivered" */}
              <StatusStateIndicator
                status={
                  sale.status == 'delivered' &&
                  sale.status == sale.statusSetByAdmin
                    ? 'delivered-by-admin'
                    : sale.status
                }
                color="gray"
                className="ps-2"
              />
            </div>
            {sale.deliveryDate &&
                <DeliveryDetails status={sale.status} deliveryDate={sale.deliveryDate}
                                 statusSetByAdmin={sale.statusSetByAdmin} adminName={sale.statusSetByAdminUser?.name}
                                 defaultMessage={isExternal ? 'delivery_date' : 'set_as_delivered_pickup'}/>}
          </div>
        </div>
      </div>
      <div className="card-body">
        <Typography variant="body2" className="pb-1">
          <b>
            {intl.formatMessage({
              id: 'dashboard.sales.details.delivery_details',
            })}
          </b>
        </Typography>
        <div className="row pt-1 gx-2">
          <div className="col-lg-6">
            {sale.customDeliveryModeSelected === 'external' && (
              <>
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.shipping_method',
                  })}
                  value={intl.formatMessage({
                    id: 'dashboard.sales.details.external_shipment',
                  })}
                />
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.shipping_company',
                  })}
                  value={get(sale, 'externalShipment.shippingCompany')}
                />
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.tracking_number',
                  })}
                  value={get(sale, 'externalShipment.trackingNumber')}
                />
              </>
            )}
            {sale.customDeliveryModeSelected === 'meetup' && (
              <>
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.shipping_method',
                  })}
                  value={intl.formatMessage({
                    id: 'dashboard.sales.details.pickup',
                  })}
                />
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.meeting_phone',
                  })}
                  value={get(sale, 'meetup.phoneNumber')}
                />
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.email_address',
                  })}
                  value={get(sale, 'meetup.email')}
                />
              </>
            )}
          </div>
        </div>
        {sale.customDeliveryModeSelected === 'meetup' && (
          <div className="row gx-2 pt-2">
            <div className="col-6 col-lg-3">
              <Typography variant="body2" color="textSecondary">
                {intl.formatMessage({
                  id: 'dashboard.sales.details.description',
                })}
              </Typography>
            </div>
            <div className="col">
              <Typography variant="body2">
                {get(sale, 'meetup.description')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaperTicketBlock;
